import React, { createContext, useContext } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { SWRConfig } from 'swr';
import { useSession } from './auth';
import { API_URL } from './constants';

const defaults: AxiosRequestConfig = {
  timeout: 4000,
  baseURL: API_URL,
  headers: {},
};

const buildClient = (
  token?: string,
  options?: AxiosRequestConfig
): AxiosInstance => {
  const config = { ...defaults, ...(options || {}) };

  if (token) {
    config.headers = { ...config.headers, Authorization: `Bearer: ${token}` };
  }

  return axios.create(config);
};

const ApiClientContext = createContext<AxiosInstance>(buildClient());

const ApiClientProvider: React.FC = ({ children }) => {
  const { token } = useSession();
  const apiClient = buildClient(token);
  const fetcher = (url) => apiClient.get(url).then((res) => res.data);

  return (
    <ApiClientContext.Provider value={apiClient}>
      <SWRConfig value={{ fetcher }}>{children}</SWRConfig>
    </ApiClientContext.Provider>
  );
};

const useApiClient = (): AxiosInstance => useContext(ApiClientContext);

export { buildClient, ApiClientProvider, useApiClient };
