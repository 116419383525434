import router, { useRouter } from 'next/router';
import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from 'react';
import Loading from '../components/Loading/Loading';
import { firebase } from '../firebaseClient';
import { User } from '../types';
import { buildClient } from './api';

interface AuthContextValue {
  token?: string;
  user?: User;
  error?: Error;
  loading: boolean;
  authenticated: boolean;
  destroy: () => Promise<void>;
  refresh: () => Promise<void>;
}

const initialContext: AuthContextValue = {
  destroy: () => Promise.resolve(),
  refresh: () => Promise.reject(new Error('No user session is available')),
  loading: true,
  authenticated: false,
};

const AuthContext = createContext<AuthContextValue>(initialContext);

export const AuthProvider: React.FC = ({ children }) => {
  const [session, setSession] = useState(initialContext);

  const destroySession = useCallback(async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        router.push('/login');
      });
  }, []);

  const refreshSession = useCallback(async () => {
    await firebase.auth().currentUser.getIdToken(true);
  }, []);

  const handleFirebaseEvent = useCallback(
    async (user?: firebase.User): Promise<void> => {
      if (!user) {
        setSession({ ...initialContext, loading: false });
        return;
      }

      const token = await user.getIdToken();
      const { data: profile } = await buildClient(token, {
        withCredentials: true,
      }).get<User>('/profile');

      setSession({
        token,
        user: profile,
        destroy: destroySession,
        refresh: refreshSession,
        loading: false,
        authenticated: true,
        error: undefined,
      });
    },
    [destroySession, refreshSession]
  );

  useEffect(() => {
    firebase.auth().onIdTokenChanged(handleFirebaseEvent);
  }, [handleFirebaseEvent]);

  return (
    <AuthContext.Provider value={{ ...session }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useSession = (): AuthContextValue => useContext(AuthContext);

export function withAuthenticatedUser<P>(
  Component: React.ComponentType<P>
): React.ComponentType<P> {
  const WithAuthenticatedUser: React.FC<P> = (props: P) => {
    const { authenticated, loading } = useSession();
    const router = useRouter();

    if (!authenticated && !loading && typeof window !== 'undefined') {
      router.push(`/login?returnPath=${encodeURIComponent(router.asPath)}`);
    }

    if (loading || !authenticated) {
      return (
        <div className="flex items-center justify-center h-screen bg-indigo-400">
          <Loading />
        </div>
      );
    }

    return <Component {...props} />;
  };

  return WithAuthenticatedUser;
}
