import React from 'react';
import '../styles/base.css';
import { ToastProvider } from 'react-toast-notifications';
import type { AppProps } from 'next/app';
import NProgress from 'nprogress';
import Router from 'next/router';
import { AuthProvider } from '../lib/auth';
import { ApiClientProvider } from '../lib/api';
import Head from 'next/head';

NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function LampantApp({ Component, pageProps }: AppProps): React.ReactElement {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
      </Head>
      <AuthProvider>
        <ToastProvider autoDismiss placement="bottom-right">
          <ApiClientProvider>
            <Component {...pageProps} />
          </ApiClientProvider>
        </ToastProvider>
      </AuthProvider>
    </>
  );
}

export default LampantApp;
